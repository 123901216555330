<template>
    <div class="settings-wrapper settings-mobile">
        <div class="settings-mobile__title" v-if="isMobile">
            <div class="settings-mobile__title__back-arrow">
                <router-link :to="{ name: 'Profile'}">
                    <img src="@/assets/images/icons/arrow-left.svg"/>
                </router-link>
            </div>
            <h5 class="h5-style">{{ __t('settings-manage.title') }}</h5>
        </div>
        <div class="container">
            <div class="settings-mobile__wrapper">
                <div class="settings-mobile__wrapper__settings-menu">
                    <div class="settings-mobile__wrapper__settings-menu__group-menu">
                        <h3 class="h3-style">{{ __t('settings.manage-title') }}</h3>
                        <router-link :to="{ name: 'SettingsManage', params: { slug: 'notifications' }}">
                            <div class="settings-mobile__wrapper__settings-menu__group-menu__options">
                                <h5 class="h5-style">{{ __t('settings.notifications') }}</h5>
                                <img src="@/assets/images/icons/forward-arrow.svg"/>
                            </div>
                        </router-link>
                    </div>
                    <div class="settings-mobile__wrapper__settings-menu__group-menu">
                        <h3 class="h3-style">{{ __t('settings.account-title') }}</h3>
                        <router-link :to="{ name: 'SettingsAccount' }">
                            <div class="settings-mobile__wrapper__settings-menu__group-menu__options">
                                <h5 class="h5-style">{{ __t('settings.account') }}</h5>
                                <img src="@/assets/images/icons/forward-arrow.svg"/>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'SettingsAccount', params: { slug: 'help-and-safety' } }">
                            <div class="settings-mobile__wrapper__settings-menu__group-menu__options">
                                <h5 class="h5-style" v-text="__t('settings.help-safety')"></h5>
                                <img src="@/assets/images/icons/forward-arrow.svg"/>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'SettingsAccount', params: { slug: 'legal' } }">
                            <div class="settings-mobile__wrapper__settings-menu__group-menu__options">
                                <h5 class="h5-style">{{ __t('settings.legal')}}</h5>
                                <img src="@/assets/images/icons/forward-arrow.svg"/>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="settings-mobile__bottom-part">
                    <div class="settings-mobile__bottom-part__log-out">
                        <a class="cursor-pointer" @click.prevent="handleLogout">
                            <h5 class="h5-style">{{ __t('settings.logout')}}</h5>
                        </a>
                    </div>
                    <div class="settings-mobile__bottom-part__delete-account">
                        <a class="cursor-pointer" @click.prevent="handleDeleteAccount">
                            <h5 class="h5-style">{{ __t('settings.delete-account')}}</h5>
                        </a>
                    </div>
                    <div class="settings-mobile__bottom-part__delete-account">
                        {{ appVersion }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
const smallDevice = window.matchMedia('(max-width: 767px)')

export default {
  name: 'Settings',
  data () {
    return {}
  },
  computed: {
    isMobile () {
      return smallDevice.matches
    },
    appVersion () {
      return process.env.VUE_APP_VERSION
    }
  },
  methods: {
    ...mapActions('auth', ['logout', 'deleteAccount']),
    handleLogout () {
      this.logout().then(() => {
        localStorage.clear()
        localStorage.setItem('environment', process.env.VUE_APP_ENV)
        this.$router.push({ name: 'Login' })
      })
    },
    handleDeleteAccount () {
      this.deleteAccount().then(resp => {
        this.$router.push({ name: 'Login' })
      })
    }
  },
  mounted () {}
}
</script>
